'use client'
import { useState } from "react";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";

const Home = () => {
    const [muted, setMuted] = useState(true);

    const toggleMute = () => {
        setMuted(!muted);
    }
    return (
        <section className="h-auto md:h-[820px] ">
            <video
                className="w-full h-auto md:h-[820px] object-cover"
                src="/HomeVid.mp4"
                autoPlay
                preload="auto"
                loop
                muted={muted}
                disablePictureInPicture
                
            />

            <div className="relative md:bottom-28 w-full">
                <div className="absolute md:-bottom-14 bottom-6 px-4 w-full flex justify-end">
                    <button onClick={toggleMute}>
                        {!muted ? <FaVolumeUp className=" transition duration-300 ease-in-out hover:scale-105 border-2 p-2 text-[46px] md:border-4 rounded-full bg-black md:p-2 md:text-[64px]" color="white" /> : <FaVolumeMute className=" transition duration-300 ease-in-out hover:scale-105 border-2 p-2 text-[46px] md:border-4 rounded-full bg-black md:p-2 md:text-[64px]" color="white" />}
                    </button>
                </div>
                
            </div>
        </section>
    );
}

export default Home;