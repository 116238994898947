import { StarIcon } from "@heroicons/react/24/solid";

const VideoEmbed = ({ story, src }) => {
    return (
        <div
            className={`bg-gradient-to-r from-6ixshooter-blue via-shiny-blue to-6ixshooter-blue text-white rounded-lg 
                shadow-md p-4 flex flex-col justify-between h-full transition-transform duration-300 
                ${story.priority ? 'scale-110 z-10' : 'scale-100'
                }`}
        >
            {/* Video Section */}
            <div className="aspect-w-9 aspect-h-16">
                <iframe
                    src={src}
                    title={`${story.name} - ${story.title}`}
                    className="rounded-md shadow-lg w-full h-96"
                    allowFullScreen
                    loading="lazy"
                />
            </div>

            {/* Content Section */}
            <div className="mt-4 flex flex-col items-center">
                <h3 className="text-xl font-semibold">{story.name}</h3>
                <p className="text-md text-gray-500">{story.title}</p>
                <div className="flex mt-2">
                    {[...Array(5)].map((_, index) => (
                        <StarIcon key={index} className="text-yellow-400 w-5 h-5" />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default VideoEmbed;
