'use client';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useRef } from 'react';
import VideoEmbed from './VideoEmbed';

const VideoSection = () => {
    const successStories = [
        { name: 'DAVID DENNIS', title: 'International PRO', videoSrc: 'https://player.vimeo.com/video/708939101?autoplay=0&title=0&byline=0&wmode=transparent&autopause=0', priority: false },
        { name: 'CARLIR CODNER', title: 'NAIA College', videoSrc: 'https://player.vimeo.com/video/708939186?autoplay=0&title=0&byline=0&wmode=transparent&autopause=0', priority: false },
        { name: 'KOLBE ASHE', title: 'NCAA Player', videoSrc: 'https://player.vimeo.com/video/708939286?autoplay=0&title=0&byline=0&wmode=transparent&autopause=0', priority: false },
        { name: 'JAMES LAPUT', title: 'International PRO', videoSrc: 'https://player.vimeo.com/video/708939200?autoplay=0&title=0&byline=0&wmode=transparent&autopause=0', priority: true }, // Priority Video
        { name: 'BEN UNDERWOOD', title: 'D2 NCAA', videoSrc: 'https://player.vimeo.com/video/708939296?autoplay=0&title=0&byline=0&wmode=transparent&autopause=0', priority: false },
        { name: 'EDEN DAVIS', title: 'D1 NCAA', videoSrc: 'https://player.vimeo.com/video/708939394?autoplay=0&title=0&byline=0&wmode=transparent&autopause=0', priority: false },
        { name: 'ZACH REEVES', title: 'NAIA player', videoSrc: 'https://player.vimeo.com/video/717698908?autoplay=0&title=0&byline=0&wmode=transparent&autopause=0', priority: false },
        { name: 'RYLE OWENS', title: 'Overseas PRO', videoSrc: 'https://player.vimeo.com/video/717698932?autoplay=0&title=0&byline=0&wmode=transparent&autopause=0', priority: false }
    ];

    const carouselRef = useRef(null);

    useEffect(() => {
        if (carouselRef.current) {
            const priorityCard = carouselRef.current.querySelector('.priority-card');
            if (priorityCard) {
                const cardWidth = priorityCard.offsetWidth;
                const offsetLeft = priorityCard.offsetLeft;
                const scrollTo = offsetLeft - (carouselRef.current.clientWidth / 2) + (cardWidth / 2);
                carouselRef.current.scrollLeft = scrollTo;
            }
        }
    }, []);

    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };

    return (
        <section className="py-12 px-4 bg-primary-gray text-white">
            {/* Section Header */}
            <div className="text-center mb-10">
                <h2 className="text-4xl md:text-5xl font-bold mb-4 text-6ixshooter-blue">
                    READY TO BE OUR NEXT SUCCESS STORY?
                </h2>
                <p className="text-2xl mb-6">
                    This Is Just A Few Of The Success Stories From Shooters All Over The World.
                </p>
            </div>

            {/* Carousel Container */}
            <div className="relative">
                {/* Left Arrow */}
                <button
                    onClick={scrollLeft}
                    aria-label="Scroll Left"
                    className="z-40 hidden md:flex absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-40 text-6ixshooter-blue rounded-full p-2 focus:outline-none"
                >
                    <ChevronLeftIcon className="w-6 h-6" />
                </button>

                {/* Carousel */}
                <div
                    className="flex space-x-8 overflow-x-auto no-scrollbar md:px-[5.5rem]"
                    ref={carouselRef}
                >
                    {successStories.map((story, index) => (
                        <div
                            key={index}
                            className={`flex-shrink-0 w-72 py-8 ${story.priority ? 'priority-card' : ''}`}
                        >
                            <VideoEmbed
                                story={story}
                                src={story.videoSrc}
                            />
                        </div>
                    ))}
                </div>

                {/* Right Arrow */}
                <button
                    onClick={scrollRight}
                    aria-label="Scroll Right"
                    className="z-40 hidden md:flex absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-40 text-6ixshooter-blue rounded-full p-2 focus:outline-none"
                >
                    <ChevronRightIcon className="w-6 h-6" />
                </button>
            </div>
        </section>
    );
};

export default VideoSection;
