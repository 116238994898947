'use client';

import { useEffect, useRef } from 'react';
import { useTransition } from 'react';
import { recordEvent } from '@/app/actions/analytics/recordEvent';

export default function ClientTracker() {
    const [isPending, startTransition] = useTransition();
    const startTimeRef = useRef(Date.now());
    const maxScrollDepthRef = useRef(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const docHeight = document.body.scrollHeight;
            const scrollPercent = ((scrollTop + windowHeight) / docHeight) * 100;
            if (scrollPercent > maxScrollDepthRef.current) {
                maxScrollDepthRef.current = Math.round(Math.min(scrollPercent, 100));
            }
        };
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');
        const variant = localStorage.getItem('variant');

        const handleUnload = () => {
            let duration = Date.now() - startTimeRef.current;
            //convert duration to format HH:MM:SS
            duration = new Date(duration).toISOString().substr(11, 8);

                
            const scrollDepth = maxScrollDepthRef.current;
            startTransition(() => {
                recordEvent({
                    sessionId,
                    eventType: 'page_exit',
                    eventData: { duration, scrollDepth, variant }
                });
            });
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                handleUnload();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    return null;
}